.blog-container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
    padding-inline: 10%;
    padding-top: 100px;
    padding-bottom: 5rem;
    @media only screen and (max-width: 800px) {
        padding-top: 60px;
        padding-bottom: 10rem;
    }
    .blog-title {
        font-size: 1.6rem;
        font-family: "InterMedium";
        color: var(--white-color);
        @media only screen and (max-width: 800px) {
            font-size: 1.2rem;
        }
    }
    .blog-miss {
        height: auto;
        width: auto;
        margin-top: 20px;
        p {
            font-size: 1.2rem;
            font-family: "InterRegular";
            color: var(--four-color);
            @media (max-width: 800px) {
                font-size: 0.8rem;
            }
        }
    }
}