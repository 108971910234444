    // Footer
    .footer {
        background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding-inline: 20px;
        padding-bottom: 1rem;
        flex-wrap: wrap;
        @media only screen and (max-width: 800px) {
            justify-content: center;
            flex-direction: column;
        }
        p {
            color: var(--five-color);
            font-size: 0.8rem;
            font-family: "InterMedium";
            @media only screen and (max-width: 800px) {
                font-size: 0.7rem;
            }
        }
        .footer-newsletter {
            display: flex;
            justify-content: center;
            flex-direction: row;
            @media only screen and (max-width: 800px) {
                margin-top: 15px;
            }
            .footer-newsletter-input {
                height: auto;
                width: auto;
                border: none;
                font-size: 0.7rem;
                background-color: transparent;
                color: var(--white-color);
                border: 0;
                outline: 0;
                border: none;
                border-bottom: 1px solid var(--four-color);
                @media only screen and (max-width: 800px) {
                    font-size: 0.6rem;
                }
                &:focus {
                    border-bottom: 1px solid var(--white-color);
                }
                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: var(--five-color);
                    opacity: 1;
                    /* Firefox */
                }
                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: var(--five-color);
                }
                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: var(--five-color);
                }
            }
            .footer-newsletter-button {
                height: auto;
                width: auto;
                background-color: transparent;
                color: var(--five-color);
                font-size: 0.8rem;
                font-family: "InterBold";
                border: 1px solid var(--five-color);
                padding: 5px 10px;
                border-radius: 5px;
                align-items: center;
                margin-left: 10px;
                &:hover {
                    background-color: var(--white-color);
                    color: var(--one-color);
                    cursor: pointer;
                }
                @media only screen and (max-width: 800px) {
                    font-size: 0.7rem;
                }
            }
        }
        .footer-logos {
            display: flex;
            justify-content: center;
            flex-direction: row;
            @media only screen and (max-width: 800px) {
                margin-top: 15px;
            }
            .footer-logo {
                margin-inline: 8px;
                height: 22px;
                width: 22px;
                color: var(--five-color);
                &:hover {
                    scale: 1.1;
                    color: var(--white-color);
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                }
                @media only screen and (max-width: 800px) {
                    height: 16px;
                    width: 16px;
                }
            }
        }
        .footer-mentions {
            text-decoration: none;
            color: var(--five-color);
            font-size: 0.8rem;
            font-family: "InterMedium";
            &:hover {
                color: var(--white-color);
                scale: 1.1;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
            }
            @media only screen and (max-width: 800px) {
                font-size: 0.7rem;
                margin-top: 15px;
            }
        }
    }