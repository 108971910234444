.error404-container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
    padding-inline: 10%;
    padding-top: 100px;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    @media only screen and (max-width: 800px) {
        padding-top: 60px;
        padding-bottom: 10rem;
    }
    .error404-text {
        height: auto;
        width: auto;
        font-size: 20rem;
        text-align: center;
        font-family: "InterMedium";
        color: var(--five-color);
        @media (max-width: 800px) {
            font-size: 10rem;
        }
    }
    .error404-subtext {
        height: auto;
        width: auto;
        text-align: center;
        font-size: 4rem;
        font-family: "InterBold";
        color: var(--five-color);
        @media (max-width: 800px) {
            font-size: 2rem;
        }
    }
    .error404-explain {
        height: auto;
        width: auto;
        text-align: center;
        font-size: 1.6rem;
        font-family: "InterRegular";
        color: var(--white-color);
        @media (max-width: 800px) {
            font-size: 1rem;
        }
    }
}