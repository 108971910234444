        .artcard-container {
            height: 350px;
            width: auto;
            min-width: 300px;
            border-radius: 5px;
            margin: 5px;
            position: relative;
            @media only screen and (max-width: 800px) {
                height: 250px;
            }
            .artcard-background-picture {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
            .artcard-details-container {
                width: 100%;
                height: 20%;
                background-color: var(--four-color);
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                padding-inline: 5%;
                position: absolute;
                bottom: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                opacity: 1;
                .artcard-title {
                    font-size: 1.4rem;
                    font-family: "InterBold";
                    text-align: center;
                    width: auto;
                    height: auto;
                    color: var(--white-color);
                    @media only screen and (max-width: 800px) {
                        font-size: 1rem;
                    }
                }
                .artcard-third-width-right {
                    width: 33%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    .artcard-button {
                        border: 1px solid var(--white-color);
                        background-color: var(--two-color);
                        border-radius: 5px;
                        padding: 4% 12%;
                        .artcard-button-logo-instagram {
                            height: 24px;
                            width: 24px;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            color: var(--white-color);
                            @media only screen and (max-width: 800px) {
                                height: 16px;
                                width: 16px;
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            background-color: var(--white-color);
                            transition: all 0.3s ease-in-out;
                             :first-child {
                                color: var(--one-color);
                            }
                        }
                    }
                }
                .artcard-third-width-left {
                    width: 33%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    .artcard-date {
                        font-size: 0.8rem;
                        font-family: "InterRegular";
                        color: var(--white-color);
                        text-align: left;
                        @media only screen and (max-width: 800px) {
                            font-size: 0.6rem;
                        }
                    }
                }
            }
        }
        
        @media (min-width: 400px) {
            .artcard-container {
                flex: 0 0 calc(80% - 10px);
            }
        }
        
        @media (min-width: 800px) {
            .artcard-container {
                flex: 0 0 calc(50% - 10px);
            }
        }
        
        @media (min-width: 1200px) {
            .artcard-container {
                flex: 0 0 calc(33.333% - 10px);
            }
        }
        
        @media (min-width: 1600px) {
            .artcard-container {
                flex: 0 0 calc(25% - 10px);
            }
        }
        
        @media (min-width: 2000px) {
            .artcard-container {
                flex: 0 0 calc(20% - 10px);
            }
        }
        
        @media (min-width: 2400px) {
            .artcard-container {
                flex: 0 0 calc(15% - 10px);
            }
        }
        
        @media (min-width: 2800px) {
            .artcard-container {
                flex: 0 0 calc(10% - 10px);
            }
        }
        
        @media (min-width: 3200px) {
            .artcard-container {
                flex: 0 0 calc(5% - 10px);
            }
        }