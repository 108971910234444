.application-container {
    height: auto;
    min-height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
    padding-inline: 10%;
    padding-top: 100px;
    padding-bottom: 5rem;
    @media only screen and (max-width: 800px) {
        padding-top: 60px;
        padding-bottom: 8rem;
    }
    .application-topbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: auto;
        width: 100%;
        .application-topbar-left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: auto;
            width: auto;
            padding-bottom: 15px;
            .application-topbar-back-arrow {
                color: var(--five-color);
                height: 36px;
                width: 36px;
                &:hover {
                    color: var(--white-color);
                    cursor: pointer;
                }
            }
            .application-topbar-text {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: auto;
                width: auto;
                margin-left: 10px;
                .application-topbar-title {
                    font-size: 1.6rem;
                    font-family: "InterMedium";
                    color: var(--white-color);
                    @media only screen and (max-width: 800px) {
                        font-size: 1.2rem;
                    }
                }
                .application-topbar-subtitle {
                    font-size: 0.8rem;
                    font-family: "InterBold";
                    color: var(--five-color);
                    margin-top: 6px;
                    @media only screen and (max-width: 800px) {
                        font-size: 0.6rem;
                    }
                }
            }
        }
        .application-topbar-shape-back {
            width: 130px;
            height: 130px;
            background-color: var(--two-color);
            border: 1px solid var(--five-color);
            border-radius: 5px;
            @media only screen and (max-width: 800px) {
                width: 80px;
                height: 80px;
            }
            .application-topbar-shape-back-logo {
                height: 80%;
                width: 80%;
                margin-top: 10%;
                margin-left: 10%;
            }
        }
    }
    .application-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: auto;
        width: 100%;
        .application-content-left {
            width: 75vw;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: -30px;
            @media only screen and (max-width: 800px) {
                margin-top: -20px;
            }
            .application-content-left-title {
                margin-bottom: 15px;
                margin-top: 30px;
                font-size: 1.2rem;
                font-family: "InterMedium";
                color: var(--five-color);
                @media only screen and (max-width: 800px) {
                    font-size: 0.9rem;
                    margin-top: 20px;
                }
            }
            .application-content-left-downloads {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                height: auto;
                width: 100%;
                .application-content-left-downloads-button {
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: auto;
                    height: auto;
                    border: 1px solid var(--five-color);
                    border-radius: 5px;
                    padding: 10px 12px;
                    align-items: center;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        background-color: var(--white-color);
                        color: var(--one-color);
                        cursor: pointer;
                    }
                    .application-content-left-downloads-button-icon {
                        height: 28px;
                        width: 28px;
                        color: var(--five-color);
                        @media only screen and (max-width: 800px) {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    .application-content-left-downloads-button-text {
                        font-size: 1rem;
                        font-family: "InterMedium";
                        margin-left: 5px;
                        color: var(--five-color);
                        @media only screen and (max-width: 800px) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            .application-content-left-description {
                font-size: 1rem;
                text-align: justify;
                font-family: "InterRegular";
                color: var(--white-color);
                @media only screen and (max-width: 800px) {
                    font-size: 0.8rem;
                }
            }
            .application-content-left-categories {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 100%;
                height: auto;
                flex-wrap: wrap;
                .application-content-left-categories-category {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 30px;
                    margin-bottom: 15px;
                    @media only screen and (max-width: 800px) {
                        margin-right: 20px;
                        margin-bottom: 10px;
                    }
                    .application-content-left-categories-category-icon {
                        height: 28px;
                        width: 28px;
                        color: var(--five-color);
                        @media only screen and (max-width: 800px) {
                            height: 20px;
                            width: 20px;
                        }
                    }
                    .application-content-left-categories-category-text {
                        font-size: 1rem;
                        font-family: "InterRegular";
                        color: var(--white-color);
                        margin-left: 6px;
                        @media only screen and (max-width: 800px) {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
        .application-content-right {
            width: 20vw;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            height: auto;
            .application-content-right-stats {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                height: auto;
                width: auto;
                margin-top: 15px;
                align-items: center;
                @media only screen and (max-width: 800px) {
                    margin-top: 10px;
                }
                .application-content-right-stats-text {
                    font-size: 0.8rem;
                    font-family: "InterMedium";
                    color: var(--white-color);
                    text-align: end;
                    @media only screen and (max-width: 800px) {
                        font-size: 0.5rem;
                    }
                }
                .application-content-right-stats-icon {
                    height: 20px;
                    width: 20px;
                    color: var(--five-color);
                    margin-left: 5px;
                    @media only screen and (max-width: 800px) {
                        height: 14px;
                        width: 14px;
                    }
                }
            }
        }
    }
    .application-captures {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        .application-capture-img {
            height: 100%;
            width: 200px;
            object-fit: cover;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 5px;
            @media only screen and (max-width: 800px) {
                height: auto;
                width: 120px;
                margin-right: 12px;
                margin-bottom: 12px;
            }
        }
    }
}