.mentions-container {
    height: auto;
    min-height: 100%;
    width: 100%;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 60%, var(--one-color) 100%);
    padding-inline: 10%;
    padding-top: 100px;
    padding-bottom: 5rem;
    @media only screen and (max-width: 800px) {
        padding-top: 60px;
        padding-bottom: 10rem;
    }
    .mentions-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .mentions-header-back-arrow {
            height: 50px;
            width: 50px;
            color: var(--five-color);
            &:hover {
                color: var(--white-color);
                scale: 1.1;
                cursor: pointer;
            }
            @media only screen and (max-width: 800px) {
                height: 34px;
                width: 34px;
            }
        }
        .mentions-header-text {
            margin-left: 10px;
            font-size: 1.6rem;
            font-family: "InterMedium";
            color: var(--white-color);
            @media only screen and (max-width: 800px) {
                font-size: 1.2rem;
            }
        }
    }
    .mentions-title {
        margin-top: 30px;
        font-size: 1.4rem;
        font-family: "InterRegular";
        color: var(--five-color);
        @media only screen and (max-width: 800px) {
            font-size: 1rem;
        }
    }
    .mentions-paragraph {
        margin-top: 15px;
        font-size: 1rem;
        font-family: "InterLight";
        color: var(--white-color);
        @media only screen and (max-width: 800px) {
            font-size: 0.8rem;
        }
    }
}