.projects-container {
    height: auto;
    min-height: 100%;
    width: 100%;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
    padding-inline: 10%;
    padding-top: 100px;
    padding-bottom: 5rem;
    @media only screen and (max-width: 800px) {
        padding-top: 60px;
        padding-bottom: 10rem;
    }
    .projects-title-filters {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
        .projects-title {
            font-size: 1.6rem;
            font-family: "InterMedium";
            color: var(--white-color);
            @media only screen and (max-width: 800px) {
                font-size: 1.2rem;
            }
        }
        .projects-filters {
            width: auto;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            @media only screen and (max-width: 800px) {
                margin-top: 15px;
                align-items: center;
            }
            .projects-filters-categories {
                width: 100%;
                height: auto;
                min-height: 45px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-right: 20px;
                background-color: var(--two-color);
                border: 1px solid var(--four-color);
                border-radius: 5px;
                padding: 10px 5px;
                @media only screen and (max-width: 800px) {
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    align-items: center;
                }
                p {
                    margin-inline: 20px;
                    font-size: 1.2rem;
                    font-family: "InterMedium";
                    transition: all 0.3s ease-in-out;
                    color: var(--five-color);
                    &:hover {
                        color: var(--white-color);
                        scale: 1.1;
                        cursor: pointer;
                    }
                    @media only screen and (max-width: 800px) {
                        font-size: 0.8rem;
                        margin: 10px;
                    }
                }
                .projects-filters-category-bold {
                    color: var(--white-color);
                    scale: 1.1;
                }
            }
            .projects-filters-logo {
                height: 45px;
                width: 45px;
                color: var(--five-color);
                border: 1px solid var(--four-color);
                border-radius: 5px;
                padding: 5px;
                @media only screen and (max-width: 800px) {
                    height: 35px;
                    width: 35px;
                }
                &:hover {
                    color: var(--one-color);
                    background-color: var(--five-color);
                    border-color: var(--one-color);
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                }
            }
            .projects-filters-logo-active {
                height: 45px;
                width: 45px;
                color: var(--one-color);
                background-color: var(--five-color);
                border: 1px solid var(--one-color);
                border-radius: 5px;
                padding: 5px;
                @media only screen and (max-width: 800px) {
                    height: 35px;
                    width: 35px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .projects-filters-list {
                height: auto;
                width: auto;
                position: absolute;
                background-color: var(--five-color);
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 5px;
                right: 1px;
                top: 40px;
                z-index: 10;
                @media only screen and (max-width: 800px) {
                    top: 40px;
                }
                @media only screen and (max-width: 421px) {
                    top: 55px;
                }
                @media only screen and (max-width: 286px) {
                    top: 75px;
                }
                @media only screen and (max-width: 204px) {
                    top: 90px;
                }
                .projects-filters-list-title {
                    font-size: 0.8rem;
                    padding: 6px 10px;
                    font-family: "InterBold";
                    color: var(--one-color);
                    text-align: center;
                    border-bottom: 1px solid var(--one-color);
                    @media only screen and (max-width: 800px) {
                        font-size: 0.6rem;
                    }
                }
                .projects-filters-list-container {
                    height: auto;
                    width: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding-inline: 5px;
                    color: var(--one-color);
                    &:hover {
                        background-color: var(--three-color);
                        color: var(--white-color);
                        cursor: pointer;
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid var(--one-color);
                    }
                    .projects-filters-list-item {
                        font-size: 1rem;
                        padding: 6px 10px;
                        font-family: "InterBold";
                        @media only screen and (max-width: 800px) {
                            font-size: 0.8rem;
                        }
                    }
                    .projects-filters-list-logo {
                        height: auto;
                        width: auto;
                    }
                }
            }
        }
    }
}

.projects-cards {
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    @media (max-width: 800px) {
        justify-content: center;
    }
    @media (min-width: 800px) {
        &:after {
            content: '';
            flex: auto;
        }
    }
    .projects-card {
        height: auto;
        width: auto;
    }
    .projects-miss {
        height: auto;
        width: auto;
        p {
            font-size: 1.2rem;
            font-family: "InterRegular";
            color: var(--four-color);
            @media (max-width: 800px) {
                font-size: 0.8rem;
            }
        }
    }
}