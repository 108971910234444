.appcard-container {
    height: 250px;
    width: auto;
    min-width: 300px;
    background-color: var(--four-color);
    display: flex;
    flex-direction: row;
    margin: 5px;
    border-radius: 5px;
    @media only screen and (max-width: 800px) {
        height: 200px;
    }
    .appcard-background {
        height: 100%;
        width: 35%;
        position: relative;
        .appcard-release-date {
            height: auto;
            width: auto;
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: var(--two-color);
            color: var(--white-color);
            padding: 3px 9px;
            font-size: 0.6rem;
            font-family: "InterMedium";
            border-radius: 5px;
            @media only screen and (max-width: 800px) {
                font-size: 0.5rem;
            }
        }
        .appcard-release-version {
            height: auto;
            width: auto;
            position: absolute;
            top: 35px;
            left: 10px;
            background-color: var(--two-color);
            color: var(--white-color);
            padding: 3px 9px;
            font-size: 0.6rem;
            font-family: "InterMedium";
            border-radius: 5px;
            @media only screen and (max-width: 800px) {
                font-size: 0.5rem;
                top: 30px;
            }
        }
        .appcard-background-picture {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
            clip-path: polygon(0 0, 100% 0, 40% 100%, 0% 100%);
        }
        .appcard-shape-back {
            width: 70px;
            height: 70px;
            background-color: var(--three-color);
            border: 1px solid var(--white-color);
            border-radius: 5px;
            position: absolute;
            top: 20%;
            right: -4%;
            @media only screen and (max-width: 800px) {
                width: 50px;
                height: 50px;
            }
            .appcard-shape-back-logo {
                height: 80%;
                width: 80%;
                margin-top: 10%;
                margin-left: 10%;
            }
        }
        .appcard-stats {
            width: auto;
            height: auto;
            position: absolute;
            bottom: 10%;
            right: 0;
            flex-direction: column;
            justify-content: flex-end;
            .appcard-stats-line {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 15%;
                p {
                    font-size: 0.8rem;
                    font-family: "InterRegular";
                    margin-right: 4%;
                    color: var(--white-color);
                    @media only screen and (max-width: 800px) {
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
    .appcard-text {
        height: 100%;
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5%;
        .appcard-specifications {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-end;
            height: auto;
            max-height: 20px;
            @media only screen and (max-width: 800px) {
                max-height: 15px;
            }
            .appcard-spec-line {
                height: 100%;
                width: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-left: 10px;
                p {
                    font-size: 0.8rem;
                    font-family: "InterRegular";
                    color: var(--white-color);
                    margin-right: 4px;
                    @media only screen and (max-width: 800px) {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        .appcard-text-title {
            font-size: 1.4rem;
            font-family: "InterBold";
            text-align: left;
            color: var(--white-color);
            @media only screen and (max-width: 800px) {
                font-size: 1rem;
            }
        }
        .appcard-text-description {
            font-size: 0.8rem;
            color: var(--white-color);
            opacity: 0.8;
            font-family: "InterLight";
            text-align: left;
            overflow-y: hidden;
            text-overflow: clip;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            line-height: 1.5;
            -webkit-box-orient: vertical;
            @media only screen and (max-width: 800px) {
                font-size: 0.6rem;
            }
        }
        .appcard-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .appcard-buttons-unavailable {
                margin-top: 3%;
                color: var(--white-color);
                opacity: 0.6;
                font-size: 1rem;
                font-family: "InterMedium";
                @media only screen and (max-width: 800px) {
                    font-size: 0.7rem;
                }
            }
            .appcard-button {
                border: 1px solid var(--white-color);
                border-radius: 5px;
                background-color: var(--two-color);
                padding: 2% 10%;
                margin-left: 15px;
                .appcard-buttons-logo {
                    height: 24px;
                    width: 24px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    color: var(--white-color);
                    @media only screen and (max-width: 800px) {
                        height: 16px;
                        width: 16px;
                    }
                }
                &:hover {
                    cursor: pointer;
                    background-color: var(--white-color);
                    transition: all 0.2s ease-in-out;
                     :first-child {
                        color: var(--one-color);
                    }
                }
            }
        }
    }
    .appcard-logo {
        height: 14px;
        width: 14px;
        color: var(--white-color);
        @media only screen and (max-width: 800px) {
            height: 12px;
            width: 12px;
        }
    }
}

@media (min-width: 400px) {
    .appcard-container {
        flex: 0 0 calc(80% - 10px);
    }
}

@media (min-width: 800px) {
    .appcard-container {
        flex: 0 0 calc(50% - 10px);
    }
}

@media (min-width: 1200px) {
    .appcard-container {
        flex: 0 0 calc(33.333% - 10px);
    }
}