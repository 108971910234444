.navbar {
    height: auto;
    width: 100%;
    padding: 20px;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
    position: fixed;
    align-items: center;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 800px) {
        padding: 0px;
    }
}

.navbar-identity {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media only screen and (max-width: 800px) {
        padding: 20px;
    }
    .navbar-name {
        color: var(--white-color);
        font-family: "VarelaRoundRegular";
        font-weight: 400;
        font-size: 1rem;
        margin-right: 10px;
        @media only screen and (max-width: 800px) {
            font-size: 0.7rem;
        }
    }
    .navbar-alias {
        color: var(--five-color);
        font-family: "VarelaRoundRegular";
        font-weight: 600;
        font-size: 1rem;
        @media only screen and (max-width: 800px) {
            font-size: 0.7rem;
        }
    }
}

.navbar-links {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style: none;
    position: relative;
    align-items: center;
    @media only screen and (max-width: 800px) {
        flex-direction: column;
        height: auto;
        width: 100%;
        background-color: var(--two-color);
    }
    .navbar-links-item {
        margin: 0 0.5rem;
        cursor: pointer;
        color: var(--five-color);
        text-decoration: none;
        font-family: "InterRegular";
        font-size: 1.4rem;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        padding: 0.3rem 0.5rem;
        @media only screen and (max-width: 800px) {
            &:nth-child(1) {
                margin-top: 50px;
            }
            font-size: 1.2rem;
            width: 100%;
            font-family: "InterMedium";
            text-align: center;
            padding: 30px;
        }
        &:hover {
            color: var(--white-color);
            background-color: var(--four-color);
            border-radius: 5px;
        }
    }
    .navbar-links-item-bold {
        color: var(--white-color);
        background-color: var(--four-color);
        border-radius: 5px;
    }
    .navbar-logo {
        align-self: center;
        color: var(--five-color);
        height: 20px;
        width: 20px;
        margin-left: 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
            color: var(--white-color);
            cursor: pointer;
            scale: 1.1;
        }
        @media only screen and (max-width: 800px) {
            height: 40px;
            width: 40px;
        }
    }
}

.navbar-logo-resp-left-bis {
    color: var(--five-color);
    height: 40px;
    width: 40px;
    margin-left: 20px;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 7px;
    left: 30px;
    padding: 10px;
    &:hover {
        color: var(--white-color);
        cursor: pointer;
        scale: 1.1;
    }
}

.navbar-logo-resp-left {
    color: var(--five-color);
    height: 40px;
    width: 40px;
    margin-left: 20px;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 7px;
    left: -10px;
    padding: 10px;
    &:hover {
        color: var(--white-color);
        cursor: pointer;
        scale: 1.1;
    }
}

.navbar-menu-logo {
    height: 40px;
    width: 40px;
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: var(--five-color);
    transition: all 0.3s ease-in-out;
    &:hover {
        scale: 1.1;
        color: var(--white-color);
        cursor: pointer;
    }
    @media only screen and (max-width: 800px) {
        display: block;
    }
}

.navbar-language-container {
    height: auto;
    width: auto;
    min-width: 85px;
    position: relative;
    margin-left: 0.2rem;
    margin-right: -0.2rem;
    &:hover {
        background-color: var(--four-color);
        border-radius: 5px;
    }
    .navbar-language {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: auto;
        width: auto;
        align-items: center;
        padding-inline: 0.3rem;
        color: var(--five-color);
        &:hover {
            color: var(--white-color);
            cursor: pointer;
        }
        .navbar-language-flag {
            height: 26px;
            width: 26px;
            object-fit: cover;
            margin-top: 2px;
        }
        .navbar-language-text {
            font-family: "InterRegular";
            font-weight: 600;
            font-size: 1rem;
            margin-left: 4px;
            text-transform: uppercase;
        }
        .navbar-language-text-active {
            color: var(--white-color);
        }
        .navbar-language-icon {
            height: 20px;
            width: 20px;
            object-fit: cover;
        }
        .navbar-language-icon-active {
            color: var(--white-color);
        }
    }
    .navbar-language-active {
        background-color: var(--four-color);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .navbar-languages-list-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        width: auto;
        min-width: 85px;
        position: absolute;
        background-color: var(--four-color);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .navbar-languages-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: auto;
            width: 100%;
            align-items: center;
            padding-inline: 0.3rem;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            color: var(--white-color);
            &:hover {
                color: var(--white-color);
                background-color: var(--three-color);
                cursor: pointer;
            }
            &:not(:last-child) {
                border-bottom: 1px solid var(--one-color);
            }
            .navbar-languages-list-flag {
                height: 20px;
                width: 20px;
                object-fit: cover;
            }
            .navbar-languages-list-text {
                font-family: "InterMedium";
                font-size: 0.8rem;
                margin-left: 4px;
                text-transform: uppercase;
            }
            .navbar-languages-list-icon {
                height: 14px;
                width: 14px;
                object-fit: cover;
            }
        }
    }
}

.navbar-language-container-resp {
    position: absolute;
    left: 90px;
    top: 12px;
}