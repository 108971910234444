.home-container {
    height: auto;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 100px;
    background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 800px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .home-message-central {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-self: center;
        .home-center {
            display: flex;
            justify-content: center;
            .home-hello {
                text-align: center;
                color: var(--white-color);
                font-family: "SourceSansProBold";
                text-transform: uppercase;
                font-size: 10rem;
                line-height: 0.8;
                text-align: start;
                @media only screen and (max-width: 800px) {
                    font-size: 5rem;
                }
            }
            .home-and {
                color: var(--five-color);
                font-family: "SourceSansProRegular";
                text-transform: uppercase;
                font-size: 4rem;
                margin-left: 6px;
                top: 24px;
                position: relative;
                @media only screen and (max-width: 800px) {
                    font-size: 2rem;
                    margin-left: 2px;
                    top: 12px;
                }
            }
        }
        .home-welcome {
            color: var(--five-color);
            left: 0;
            font-family: "SourceSansProRegular";
            text-transform: uppercase;
            font-size: 4rem;
            text-align: center;
            line-height: 1;
            margin-top: 20px;
            @media only screen and (max-width: 800px) {
                font-size: 2rem;
                margin-top: 10px;
            }
        }
    }
}