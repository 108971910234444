        .about-container {
            height: auto;
            width: 100vw;
            background: linear-gradient(90deg, var(--one-color) 0%, var(--two-color) 50%, var(--one-color) 100%);
            padding-inline: 10%;
            padding-top: 100px;
            padding-bottom: 5rem;
            @media only screen and (max-width: 800px) {
                padding-top: 60px;
                padding-bottom: 10rem;
            }
            .about-title {
                font-size: 1.6rem;
                font-family: "InterMedium";
                color: var(--white-color);
                @media only screen and (max-width: 800px) {
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
            .about-content {
                height: auto;
                width: auto;
                display: flex;
                flex-direction: row;
                margin-top: 40px;
                @media only screen and (max-width: 800px) {
                    flex-direction: column;
                    margin-top: 20px;
                }
                .about-content-left {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    height: auto;
                    width: 30%;
                    padding-inline: 1%;
                    @media only screen and (max-width: 800px) {
                        width: 100%;
                    }
                    .about-profile-picture {
                        height: auto;
                        width: 60%;
                        border-radius: 5px;
                        align-self: center;
                        @media only screen and (max-width: 800px) {
                            width: 30%;
                            min-width: 150px;
                        }
                    }
                    .about-profile-name {
                        font-size: 1.4rem;
                        align-self: center;
                        margin-top: 20px;
                        margin-bottom: 10px;
                        font-family: "InterMedium";
                        color: var(--white-color);
                        @media (max-width: 800px) {
                            font-size: 0.8rem;
                        }
                    }
                    .about-profile-details {
                        font-size: 1rem;
                        text-align: center;
                        margin-top: 5px;
                        font-family: "InterMedium";
                        color: var(--five-color);
                        @media (max-width: 800px) {
                            font-size: 0.7rem;
                        }
                    }
                    .bottom-15 {
                        padding-bottom: 15px;
                    }
                    .about-profile-details-list-container {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        height: auto;
                        width: auto;
                        margin-inline: auto;
                        margin-bottom: 10px;
                        .about-profile-details-list {
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 8px;
                            .about-profile-details-list-icon {
                                height: 24px;
                                width: 24px;
                                color: var(--five-color);
                                @media (max-width: 800px) {
                                    align-self: center;
                                    height: 18px;
                                    width: 18px;
                                }
                            }
                            .about-profile-details-list-text {
                                font-size: 0.8rem;
                                align-self: center;
                                margin-left: 10px;
                                font-family: "InterMedium";
                                color: var(--white-color);
                                @media (max-width: 800px) {
                                    font-size: 0.6rem;
                                }
                            }
                        }
                    }
                    .about-profile-logos {
                        display: flex;
                        justify-content: center;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: 15px;
                        .about-profile-logo {
                            margin-inline: 8px;
                            height: 36px;
                            width: 36px;
                            color: var(--five-color);
                            border: 1px solid var(--five-color);
                            border-radius: 5px;
                            padding: 6px;
                            margin-top: 20px;
                            &:hover {
                                color: var(--four-color);
                                background-color: var(--white-color);
                                cursor: pointer;
                                transition: all 0.3s ease-in-out;
                            }
                            @media only screen and (max-width: 800px) {
                                height: 28px;
                                width: 28px;
                                padding: 5px;
                                margin-top: 15px;
                            }
                        }
                    }
                    .about-profile-languages-container {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        height: auto;
                        width: auto;
                        margin-inline: auto;
                        margin-bottom: 20px;
                        .about-profile-languages {
                            height: auto;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 10px;
                            .about-profile-languages-flag {
                                width: 36px;
                                height: 36px;
                                border-radius: 5px;
                                object-fit: cover;
                                @media only screen and (max-width: 800px) {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .about-profile-languages-texts {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                margin-left: 10px;
                                .about-profile-languages-language {
                                    font-size: 0.9rem;
                                    font-family: "InterBold";
                                    color: var(--five-color);
                                    @media only screen and (max-width: 800px) {
                                        font-size: 0.7rem;
                                    }
                                }
                                .about-profile-languages-level {
                                    font-size: 0.8rem;
                                    font-family: "InterRegular";
                                    color: var(--white-color);
                                    margin-top: 4px;
                                    @media only screen and (max-width: 800px) {
                                        font-size: 0.6rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .about-content-right {
                    height: auto;
                    width: 70%;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    padding-inline: 1%;
                    @media (max-width: 800px) {
                        width: 100%;
                    }
                    .about-cv-downloads {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        margin-top: 10px;
                        @media only screen and (max-width: 800px) {
                            justify-content: space-between;
                        }
                        .about-cv-download {
                            width: auto;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-right: 4%;
                            margin-bottom: 20px;
                            @media only screen and (max-width: 800px) {
                                margin-right: 1%;
                            }
                            @media only screen and (max-width: 224px) {
                                margin: auto;
                                margin-bottom: 20px;
                            }
                            .about-cv-download-lang {
                                display: flex;
                                justify-content: space-between;
                                flex-direction: row;
                                .about-cv-download-text {
                                    margin-top: 2px;
                                    font-size: 1rem;
                                    font-family: "InterRegular";
                                    color: var(--white-color);
                                    @media only screen and (max-width: 800px) {
                                        font-size: 0.8rem;
                                    }
                                }
                                .about-cv-download-flag {
                                    height: 26px;
                                    width: 26px;
                                    object-fit: cover;
                                    border-radius: 5px;
                                    @media only screen and (max-width: 800px) {
                                        height: 18px;
                                        width: 18px;
                                    }
                                }
                            }
                            .about-cv-download-button {
                                width: auto;
                                height: auto;
                                margin-top: 10px;
                                padding: 6px 12px;
                                font-size: 1rem;
                                font-family: "InterRegular";
                                color: var(--five-color);
                                border: 1px solid var(--five-color);
                                border-radius: 5px;
                                text-align: center;
                                @media only screen and (max-width: 800px) {
                                    font-size: 0.8rem;
                                }
                                &:hover {
                                    background-color: var(--white-color);
                                    color: var(--one-color);
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .about-content-right-title {
                        font-size: 1.4rem;
                        font-family: "InterMedium";
                        text-align: justify;
                        color: var(--five-color);
                        @media (max-width: 800px) {
                            font-size: 0.8rem;
                            margin-top: 20px;
                        }
                    }
                    .about-content-right-paragraph {
                        margin-top: 10px;
                        font-size: 1rem;
                        line-height: 1.4;
                        font-family: "InterRegular";
                        text-align: justify;
                        color: var(--white-color);
                        margin-bottom: 20px;
                        @media (max-width: 800px) {
                            font-size: 0.8rem;
                            margin-bottom: 0px;
                        }
                    }
                    .about-content-formations {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        margin-top: 10px;
                        padding-bottom: 10px;
                        @media (max-width: 400px) {
                            flex-direction: column;
                            justify-content: center;
                        }
                        .about-content-formations-left {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 30%;
                            max-width: 140px;
                            min-width: 80px;
                            height: auto;
                            @media (max-width: 400px) {
                                max-width: 100%;
                                margin: auto;
                                margin-bottom: 15px;
                            }
                            .about-content-formations-text {
                                font-size: 1rem;
                                font-family: "InterMedium";
                                text-align: center;
                                text-transform: uppercase;
                                color: var(--white-color);
                                margin-bottom: 10px;
                                @media (max-width: 800px) {
                                    font-size: 0.8rem;
                                }
                            }
                            .about-content-formations-button {
                                width: auto;
                                height: auto;
                                text-decoration: none;
                                padding: 6px 12px;
                                font-size: 1rem;
                                font-family: "InterRegular";
                                color: var(--five-color);
                                border: 1px solid var(--five-color);
                                border-radius: 5px;
                                text-align: center;
                                @media only screen and (max-width: 800px) {
                                    font-size: 0.8rem;
                                }
                                &:hover {
                                    background-color: var(--white-color);
                                    color: var(--one-color);
                                    cursor: pointer;
                                }
                            }
                        }
                        .about-content-formations-right {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            padding-left: 2%;
                            width: 100%;
                            height: auto;
                            .about-content-formations-details {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;
                                height: auto;
                                margin-bottom: 6px;
                                .about-content-formations-detail {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    width: auto;
                                    height: auto;
                                    align-items: center;
                                    @media (max-width: 400px) {
                                        margin-right: 5px;
                                        justify-content: space-between;
                                    }
                                    .about-content-formations-icon {
                                        height: 20px;
                                        width: 20px;
                                        color: var(--five-color);
                                    }
                                    .about-content-formations-text {
                                        font-size: 0.9rem;
                                        font-family: "InterMedium";
                                        text-align: left;
                                        color: var(--five-color);
                                        margin-left: 6px;
                                        @media (max-width: 800px) {
                                            font-size: 0.7rem;
                                        }
                                    }
                                }
                            }
                            .about-content-formation-description {
                                font-size: 0.9rem;
                                font-family: "InterRegular";
                                text-align: justify;
                                color: var(--white-color);
                                line-height: 1.4;
                                white-space: pre-wrap;
                                @media (max-width: 800px) {
                                    font-size: 0.7rem;
                                }
                                @media (max-width: 400px) {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .about-content-skills-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 100%;
                        height: auto;
                        margin-bottom: 20px;
                        .about-content-skills-title {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            margin-inline: 20px;
                            margin-top: 20px;
                            margin-bottom: 10px;
                            .about-content-skills-title-line {
                                background-color: var(--five-color);
                                height: 1px;
                                width: 10px;
                            }
                            .about-content-skills-title-text {
                                font-size: 1rem;
                                font-family: "InterMedium";
                                text-align: justify;
                                color: var(--five-color);
                                white-space: pre-wrap;
                                margin-inline: 6px;
                                @media (max-width: 800px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                        .about-content-skills-content {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            width: 100%;
                            height: auto;
                            flex-wrap: wrap;
                            .about-content-skills-content-skill {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                margin-inline: 20px;
                                .about-content-skills-content-skill-icon {
                                    height: 20px;
                                    width: 20px;
                                    color: var(--five-color);
                                }
                                .about-content-skills-content-skill-text {
                                    font-size: 0.9rem;
                                    font-family: "InterRegular";
                                    text-align: justify;
                                    color: var(--white-color);
                                    white-space: pre-wrap;
                                    margin-left: 4px;
                                    @media (max-width: 800px) {
                                        font-size: 0.7rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }