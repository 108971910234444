@font-face {
    font-family: InterBold;
    src: url(./assets/fonts/Inter-Bold.ttf);
}

@font-face {
    font-family: InterLight;
    src: url(./assets/fonts/Inter-Light.ttf);
}

@font-face {
    font-family: InterMedium;
    src: url(./assets/fonts/Inter-Medium.ttf);
}

@font-face {
    font-family: InterRegular;
    src: url(./assets/fonts/Inter-Regular.ttf);
}

@font-face {
    font-family: VarelaRoundRegular;
    src: url(./assets/fonts/VarelaRound-Regular.ttf);
}

@font-face {
    font-family: SourceSansProRegular;
    src: url(./assets/fonts/SourceSansPro-Regular.ttf);
}

@font-face {
    font-family: SourceSansProBold;
    src: url(./assets/fonts/SourceSansPro-Bold.ttf);
}

[data-theme='light'] {
    --one-color: #e2e9f1;
    --two-color: #d4dce9;
    --three-color: #c9daee;
    --four-color: #bccee2;
    --five-color: #48668b;
    /* --five-color: #7791D4; */
    --white-color: black;
    --black-color: white;
}

[data-theme='dark'] {
    --one-color: #06090D;
    --two-color: #121B26;
    --three-color: #253140;
    --four-color: #405670;
    --five-color: #5d7fa9;
    /* --five-color: #7791D4; */
    --white-color: white;
    --black-color: black;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}